<template>
  <default-layout :header-title="$t('menu')" :center-title="false">
    <div class="px-3">
      <template v-if="!menuLoading">
        <div class="primary-block primary-nav-list">
          <h3 class="title">{{ $t('products') }} </h3>
          <router-link :to="{name:'insurance.types', params:{id:type.id}}"
                       @click.native="productsClicked(type.id)"
                       class="nav-item"
                       v-for="type in menuInsuranceTypes"
                       :key="type.id"
          >
            <div class="icon-box"><img :src="type.icon"/></div>
            <p class="ms-3 mb-0">{{ type.title }}</p>
          </router-link>

          <router-link to="/sos-navigation" class="nav-item">
            <div class="icon-box"><icon icon-name="person-icon"/></div>
            <p class="ms-3 mb-0">{{ $t('insuredCase') }} </p>
          </router-link>

          <router-link to="/payment-number-contract" class="nav-item">
            <div class="icon-box"><img src="/img/salary.svg"></div>
            <p class="ms-3 mb-0">{{ $t('pay') }} </p>
          </router-link>

          <router-link to="/about-us" class="nav-item" @click.native="productsClicked('select_about_us')">
            <div class="icon-box"><icon icon-name="info-icon"/></div>
            <p class="ms-3 mb-0">{{ $t('aboutUs') }} </p>
          </router-link>

          <router-link to="/promotions" class="nav-item" @click.native="productsClicked('select_promo')">
            <div class="icon-box"><icon icon-name="gift-icon"></icon></div>
            <p class="ms-3 mb-0">{{ $t('specialOffers') }} </p>
          </router-link>

          <router-link to="/language" class="nav-item" @click.native="productsClicked('select_language')">
            <div class="icon-box"><icon icon-name="lang-icon"></icon></div>
            <p class="ms-3 mb-0">{{ $t('language') }} </p>
          </router-link>

          <a class="nav-item" @click="accountLogOut" v-if="!fromHomebank">
            <div class="icon-box"><icon icon-name="logout-icon"></icon></div>
            <p class="ms-3 mb-0">{{ $t('logout') }} </p>
          </a>
        </div>
      </template>
      <template v-else>
        <div class="primary-block primary-nav-list">
          <div class="nav-item border-bottom-0" v-for="(item, index) in 6" :key="index">
            <div class="icon-box skeleton"></div>
            <p class="skeleton ms-3 skeleton-text"></p>
          </div>
        </div>
      </template>
    </div>
  </default-layout>
</template>

<script>
import eventLogger from '../../services/eventLogger';
import {mapActions} from "vuex";

export default {
  name: "Menu",
  data() {
    return {
      menuInsuranceTypes: [],
      menuLoading: true,
    }
  },
  computed:{
    fromHomebank() {
      return !!this.$store.getters['homebankToken']
    }
  },
  mounted() {
    eventLogger('view_menu_tab')
    this.getMenuInsuranceTypes();
  },
  methods: {
    ...mapActions(['logOut']),
    async getMenuInsuranceTypes() {
      try {
        const {data} = await window.axios.get('insurance-types/menu');
        this.menuInsuranceTypes = data;
        this.menuLoading = false;
      } catch (e) {
        throw e;
      }
    },
    productsClicked(productTypeId) {
      let productName;

      switch(productTypeId) {
        case 1:
          productName = 'select_car_insurance';
          break;
        case 2:
          productName = 'select_home_insurance'
          break;
        case 3:
          productName = 'select_health_insurance'
          break;
        case 4:
          productName = 'select_travel_insurance'
          break;
        case 5:
          productName = 'select_fin_insurance'
          break;
        case 'select_about_us':
          productName = 'select_promo';
          break;
        case 'select_promo':
          productName = 'select_promo';
          break;
        case 'select_language':
          productName = 'select_language';
          break;
        default:
          productName = 'undefined'
          break;
      }

      eventLogger(productName, {type: 'from_menu'})
    },

    async accountLogOut() {
      let loader = this.$loading.show({});
      await this.logOut();
      location.replace('/');
      loader.hide()
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
